import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link, StatusOK, StatusPending } from '@backstage/core-components';
import { CardAccordion, CardAccordionDetails, CardAccordionSummary } from '../../CardAccordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Icons } from '../Icons';
import { useTypographyStyles } from '../typographyStyles';
import { SpaDetails } from '../useSpasFromSystem';

export const SpaAccordionCard = ({ spa }: { spa: SpaDetails }) => {
  const typoStyles = useTypographyStyles();
  let Status: ({children}: any) => JSX.Element;

  switch (spa.status) {
    case 'Ok':
      Status = StatusOK
      break;
    default:
      Status = StatusPending;
  }

  return (
    <CardAccordion>
      <CardAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            {Icons.Service}
          </Grid>
          <Grid item>
            <Typography>
              <Link to={`/catalog/default/component/${spa.name}`}>{spa.name}</Link>
            </Typography>
          </Grid>
        </Grid>
      </CardAccordionSummary>
      <CardAccordionDetails>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h2" className={typoStyles.label}>
              Description
            </Typography>
            <Typography variant="body2" className={typoStyles.description}>
              {spa.description ?? 'No description'}
            </Typography>
          </Grid>
          <Grid item container spacing={2} xs={12} direction='row'>
            <Grid item>
              <Typography variant="h2" className={typoStyles.label}>
                STATUS
              </Typography>
              <Typography variant="body2" className={typoStyles.value}>
                <Status>{spa.status}</Status>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2" className={typoStyles.label}>
                STACK
              </Typography>
              <Typography variant="body2" className={typoStyles.value}>
                {spa.stack}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2" className={typoStyles.label}>
                VERSION
              </Typography>
              <Typography variant="body2" className={typoStyles.value}>
                {spa.version}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item><Link to={`${spa.source}`} variant="body2" className={typoStyles.link}>Source</Link></Grid>
            <Grid item><Link to={`${spa.build}`} variant="body2" className={typoStyles.link}>Build</Link></Grid>
            <Grid item><Link to={`${spa.release}`} variant="body2" className={typoStyles.link}>Release</Link></Grid>
            <Grid item><Link to={`${spa.url}`} variant="body2" className={typoStyles.link}>Open</Link></Grid>
          </Grid>
        </Grid>
      </CardAccordionDetails>
    </CardAccordion>
  );
} 
