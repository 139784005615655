import { Entity } from '@backstage/catalog-model';
import { configApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';

export interface SpaDetails {
  name: string;
  description: string | undefined;
  status: string | undefined;
  stack: string | undefined;
  version: string | undefined;
  source: string | undefined;
  build: string | undefined;
  release: string | undefined;
  url: string | undefined;
}

export type SpasLoadingStatus = {
  spas?: SpaDetails[];
  loading: boolean;
  error?: Error;
};

export function useSpasFromSystem(entity: Entity): SpasLoadingStatus {
  const config = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);

  const {
    loading,
    value: spas,
    error,
  } = useAsync(async () => {
    if (entity.kind === 'System') {
      const backendUrl = config.getString('backend.baseUrl');
      const { token: idToken } = await identityApi.getCredentials();

      const response = await fetch(`${backendUrl}/api/proxy/blackbaud/spas?scs=${entity.metadata.name}`, {
        headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      }

      return [];
    }

    return [];
  }, [entity]);

  return {
    spas,
    loading,
    error,
  };
}
