/**
 * This file contains common functions shared by two other files.
 * 
 * These functions are reusable and used by multiple components or modules.
 * By centralizing them in this file, we promote code reusability and maintainability.
 * 
 * Please ensure that modifications to these functions are carefully tested, as they may
 * impact multiple parts of the codebase.
 * 
 * Note: If you make changes to this file, make sure to test the affected components or
 * modules that rely on these functions to ensure proper functionality.
 */
export function calculateExpirationDate(expirationDateString: string) {
    const expirationDate = new Date(expirationDateString);
    const remainingTime = expirationDate.getTime() - Date.now();
    return Math.floor(remainingTime / (1000 * 60 * 60 * 24));
  }
  
export function formatDate(date: string): string {
    const dateObject = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    };
    return new Intl.DateTimeFormat('en-US', options).format(dateObject);
  }