import React from 'react';
import {
  Table,
  TableColumn,
  Link,
} from '@backstage/core-components';
import { useEngineeringPlatform } from '../../useEngineeringPlatform';
import { calculateExpirationDate, formatDate } from '../CommonFunctions';

const generateServicePrincipalsData = () => {
  const { entities } = useEngineeringPlatform({
    kind: 'resource',
    type: 'service principals',
  });

  if (!entities) return [];

  const data = entities.map(item => {
    const {
      metadata: { links },
    } = item;
    return {
      name: item.metadata.name,
      link: links?.[0]?.url,
      type: item.spec?.type,
      expiresin: item.spec?.['expirationDate'] ? calculateExpirationDate(`${item.spec?.['expirationDate']}`) : 0 ,
      expiration: item.spec?.['expirationDate'] ? formatDate(`${item.spec?.['expirationDate']}`) : 'Undefined' ,
      subscriptionname: `${item.spec?.subscriptionName}`.toLowerCase(),
      keyvaultname: item.spec?.keyvaultName,
      secretname: item.spec?.secretName,
    };
  });

  return data;
};

const columns: TableColumn[] = [
  {
    title: 'Service Principal Name',
    render: (row: Partial<TableSPData>) => (
      <>
        <Link to={row.link !== undefined ? row.link : ''}>{row.name}</Link>
      </>
    ),
    highlight: true,
    width: '45%',
  },
  {
    title: 'Service Principal Type',
    field: 'type',
    width: '12%',
  },
  {
    title: 'Subscription Name',
    field: 'subscriptionname',
    width: '12%',
  },
  {
    title: 'Expires In',
    field: 'expiresin',
    width: '5%',

  },
  {
    title: 'Expiration',
    field: 'expiration',
    width: '5%',
  },
  {
    title: 'Key Vault Name',
    field: 'keyvaultname',
  },
  {
    title: 'Secret Name',
    field: 'secretname',
  },
];

export const ServicePrincipals = () => {
  return (
    <Table
      options={{ paging: false, padding: 'dense' }}
      data={generateServicePrincipalsData()}
      columns={columns}
      title="Service Principals"
    />
  );
};



interface TableSPData {
  name: string;
  link: string;
  type: string;
  subscriptionname: string;
  expiresin: number;
  expiration: string;
  keyvaultname: string;
  secretname: string;
}