import React from 'react';
import {
  Header,
  TabbedLayout,
} from '@backstage/core-components';
import { Certificates } from './Certificates/Certificates';
import { ServicePrincipals } from './ServicePrincipals/ServicePrincipals';

export const RotationTrackingPage = () => {
  return (
    <>
    <Header title="Rotation Tracking" subtitle="Assist in understanding current status for all resources that need manual rotations">
    </Header>
    <TabbedLayout>
      <TabbedLayout.Route path="/certificates" title="Certificates">
        <Certificates />
      </TabbedLayout.Route>
      <TabbedLayout.Route path="/service-principals" title="Service Principals">
        <ServicePrincipals />
      </TabbedLayout.Route>
    </TabbedLayout>
    </>
  );
};

export default RotationTrackingPage;
