import { Entity, parseEntityRef } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import useAsync from 'react-use/lib/useAsync';

export function useRelatedEntities(
  entity: Entity,
  relationFilter: { type?: string; kind?: string },
  entityFilters: Record<string, string> | undefined = undefined
): {
  entities: Entity[] | undefined;
  loading: boolean;
  error: Error | undefined;
} {
  const filterByTypeLower = relationFilter?.type?.toLocaleLowerCase('en-US');
  const filterByKindLower = relationFilter?.kind?.toLocaleLowerCase('en-US');

  const catalogApi = useApi(catalogApiRef);
  const {
    loading,
    value: entities,
    error,
  } = useAsync(async () => {
    const relations = entity.relations
      ?.map(r => ({ type: r.type, target: parseEntityRef(r.targetRef) }))
      .filter(
        r =>
          (!filterByTypeLower ||
            r.type.toLocaleLowerCase('en-US') === filterByTypeLower) &&
          (!filterByKindLower || r.target.kind === filterByKindLower),
      );

    if (!relations) {
      return [];
    }

    const results = await Promise.all(relations.flatMap(rs => {
      return catalogApi.getEntities({
        filter: {
          kind: filterByKindLower ?? '',
          'metadata.namespace': rs.target.namespace,
          'metadata.name': rs.target.name,
          ...entityFilters,
        },
      });
    }),
    );

    return results.flatMap(r => r.items);
  }, [entity, filterByTypeLower, filterByKindLower]);

  return {
    entities,
    loading,
    error,
  };
}
