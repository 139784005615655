import React from 'react';
import { ZoneStatsCard } from '../ZoneStatsCard/ZoneStatsCard';
import { useEntity } from '@backstage/plugin-catalog-react';
import { zoneComparer } from '../../../utils';
import { Grid, makeStyles } from '@material-ui/core';

const useContainerStyles = makeStyles(_ => ({
  zoneCard: {
    minWidth: '200px',
    maxWidth: '400px'
  },
}));

export const SystemZonesCard = () => {
  const containerStyles = useContainerStyles();
  const { entity } = useEntity();
  const zones: string[] = entity.spec?.zones as string[];

  return (
    <Grid container spacing={2} alignItems='stretch'>
      {zones.sort(zoneComparer).map(zone => {
        return (<Grid key={zone} item xs className={containerStyles.zoneCard}>
          <ZoneStatsCard zone={zone} />
        </Grid>)
      })}
    </Grid>
  );
} 
