import { Entity } from '@backstage/catalog-model';
import { useApi } from '@backstage/core-plugin-api';
import { catalogApiRef } from '@backstage/plugin-catalog-react';
import { useAsync } from 'react-use';

export function useEngineeringPlatform(entityFilters: {
  kind: string;
  type: string;
}): { entities: Entity[] | undefined; } 
{  
  const catalogApi = useApi(catalogApiRef);
  const { value: entities } = useAsync(async () => {
    const results = await catalogApi.getEntities({
      filter: {
        kind: entityFilters.kind,
        'spec.type': entityFilters.type,
      },
    });
    
    if (!results) {
      return [];
    }
    
    return results.items;
  });
  return {
    entities
  };
}