import { Entity } from '@backstage/catalog-model';
import { configApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';

interface EntityCostCategory {
  current: number;
  previous: number;
  change: number;
  trendline: number[];
}

interface EntityCostData {
  total: EntityCostCategory;
  zones: {
    [key: string]: EntityCostCategory;
  }
}

const defaultCostData = {
  total: { current: 0, previous: 0, change: 0, trendline: [0, 0] },
  zones: {}
};

export type CostLoadingStatus = {
  costs?: EntityCostData;
  loading: boolean;
  error?: Error;
};

export function useCostsFromEntity(entity: Entity): CostLoadingStatus {
  const config = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);

  const {
    loading,
    value: costs,
    error,
  } = useAsync(async () => {
    if (entity.kind === 'System') {
      const backendUrl = config.getString('backend.baseUrl');
      const { token: idToken } = await identityApi.getCredentials();

      const response = await fetch(`${backendUrl}/api/proxy/blackbaud/costs?scs=${entity.metadata.name}`, {
        headers: idToken ? { Authorization: `Bearer ${idToken}` } : {},
      });
      if (response.ok) {
        const data = await response.json();
        return data;
      }

      return defaultCostData;
    }

    return defaultCostData;
  }, [entity]);

  return {
    costs,
    loading,
    error,
  };
}
