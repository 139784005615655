import { makeStyles } from '@material-ui/core';

export const useTypographyStyles = makeStyles(theme => ({
  value: {
    fontWeight: 'bold',
    overflow: 'hidden',
    lineHeight: '24px',
    wordBreak: 'break-word',
  },
  description: {
    overflow: 'hidden',
    lineHeight: '24px',
    wordBreak: 'break-word',
  },
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  link: {
    textTransform: 'uppercase',
    letterSpacing: '1.2px',
    fontWeight: 600,
    fontSize: '0.7rem'
  },
  trendSummaryText: {
    fontSize: '18px',
    fontWeight: 'bold',
  },
  trendSummaryPercentChange: {
    fontSize: '12px'
  }
}));