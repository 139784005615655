import React from 'react';
import { InfoCard, InfoCardVariants, Progress, ResponseErrorPanel } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useSpasFromSystem } from '../useSpasFromSystem';
import { SpaAccordionCard } from '../SpaAccordionCard/SpaAccordionCard';

export const SystemSpasCard = ({ variant }: { variant: InfoCardVariants | undefined }) => {
  const { entity } = useEntity();

  const { loading, error, spas } = useSpasFromSystem(entity);

  if (loading) {
    return (
      <InfoCard variant={variant} title="Spas">
        <Progress />
      </InfoCard>
    );
  }

  if (error) {
    return (
      <InfoCard variant={variant} title="Spas">
        <ResponseErrorPanel error={error} />
      </InfoCard>
    );
  }

  return (
    <InfoCard title="Spas" noPadding>
      {spas?.map(spa => <SpaAccordionCard key={spa?.name} spa={spa} />)}
    </InfoCard>
  );
} 
