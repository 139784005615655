import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link, StatusOK } from '@backstage/core-components';
import { CardAccordion, CardAccordionDetails, CardAccordionSummary } from '../../CardAccordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Icons } from '../Icons';
import { Entity } from '@backstage/catalog-model';
import { useTypographyStyles } from '../typographyStyles';

export const ServiceAccordionCard = ({ entity }: { entity: Entity }) => {
  const typoStyles = useTypographyStyles();
  return (
    <CardAccordion>
      <CardAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            {Icons.Service}
          </Grid>
          <Grid item>
            <Typography>
              <Link to={`/catalog/default/component/${entity.metadata.name}`}>{entity.metadata.name}</Link>
            </Typography>
          </Grid>
        </Grid>
      </CardAccordionSummary>
      <CardAccordionDetails>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h2" className={typoStyles.label}>
              Description
            </Typography>
            <Typography variant="body2" className={typoStyles.description}>
              {entity.metadata.description ?? 'No description'}
            </Typography>
          </Grid>
          <Grid item container spacing={2} xs={12} direction='row'>
            <Grid item>
              <Typography variant="h2" className={typoStyles.label}>
                STATUS
              </Typography>
              <Typography variant="body2" className={typoStyles.value}>
                <StatusOK>Unknown</StatusOK>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2" className={typoStyles.label}>
                STACK
              </Typography>
              <Typography variant="body2" className={typoStyles.value}>
                Unknown
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2" className={typoStyles.label}>
                VERSION
              </Typography>
              <Typography variant="body2" className={typoStyles.value}>
                Unknown
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item><Link to={`https://dev.azure.com/blackbaud/Products/_git/${entity.spec?.repositoryName}`} variant="body2" className={typoStyles.link}>Source</Link></Grid>
            <Grid item><Link to={`https://dev.azure.com/blackbaud/Products/_git/${entity.spec?.repositoryName}`} variant="body2" className={typoStyles.link}>Build</Link></Grid>
            <Grid item><Link to={`https://dev.azure.com/blackbaud/Products/_git/${entity.spec?.repositoryName}`} variant="body2" className={typoStyles.link}>Release</Link></Grid>
          </Grid>
        </Grid>
      </CardAccordionDetails>
    </CardAccordion>
  );
} 