import { Accordion, AccordionDetails, AccordionSummary, SvgIcon, withStyles } from '@material-ui/core';

export const CardAccordion = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:before': {
      display: 'none'
    },
    '&$expanded': {
      margin: 'auto'
    }
  },
  expanded: {}
})(Accordion);

export const CardAccordionSummary = withStyles({
  root: {
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '12px 0'
    }
  },
  expanded: {}
})(AccordionSummary);

export const MonoEntityIcon = withStyles({
  root: {
    filter: 'invert(15%) sepia(100%) saturate(1) hue-rotate(170deg)'
  }
})(SvgIcon);

export const CardAccordionDetails = AccordionDetails;
