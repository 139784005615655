import React from 'react';
import { InfoCard, InfoCardVariants, Progress, ResponseErrorPanel } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useRelatedEntities } from '../useRelatedEntities';
import { ServiceAccordionCard } from '../ServiceAccordionCard/ServiceAccordionCard';
import { RELATION_HAS_PART } from '@backstage/catalog-model';

export const SystemServicesCard = ({ variant }: { variant: InfoCardVariants | undefined }) => {
  const { entity } = useEntity();
  const { entities, loading, error } = useRelatedEntities(entity, { type: RELATION_HAS_PART, kind: 'Component' }, { 'spec.type': 'Service' });

  if (loading) {
    return (
      <InfoCard variant={variant} title="Services">
        <Progress />
      </InfoCard>
    );
  }

  if (error) {
    return (
      <InfoCard variant={variant} title="Services">
        <ResponseErrorPanel error={error} />
      </InfoCard>
    );
  }

  return (
    <InfoCard title="Services" noPadding>
      {entities?.map(e => <ServiceAccordionCard key={e.metadata.name} entity={e} />)}
    </InfoCard>
  );
} 
