import { useEntity } from '@backstage/plugin-catalog-react';
import React, { useContext } from 'react';
import { useCostsFromEntity, CostLoadingStatus } from './useCostsFromEntity';

const context = React.createContext<CostLoadingStatus>({ loading: true });

export function useEntityCosts() {
  return useContext(context);
}

export const EntityCostsProvider = ({ children }: any) => {
  const { entity } = useEntity();
  const value = useCostsFromEntity(entity);
  const { Provider } = context;
  return (
    <Provider value={value}>
      {children}
    </Provider>
  );
}