import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
} from '@backstage/integration-react';
import {
  analyticsApiRef,
  AnyApiFactory,
  configApiRef,
  createApiFactory,
  discoveryApiRef,
  fetchApiRef,
  identityApiRef,
} from '@backstage/core-plugin-api';
import { ScaffolderClient } from '@backstage/plugin-scaffolder';
import { scaffolderApiRef } from '@backstage/plugin-scaffolder-react';
import { MixpanelAnalytics } from './analytics/MixpanelAnalytics';
import {
  graphQlBrowseApiRef,
  GraphQLEndpoints,
} from '@backstage-community/plugin-graphiql';

export const apis: AnyApiFactory[] = [
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  ScmAuth.createDefaultApiFactory(),
  createApiFactory({
    api: scaffolderApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      scmIntegrationsApi: scmIntegrationsApiRef,
      fetchApi: fetchApiRef,
      identityApi: identityApiRef
    },
    factory: ({ discoveryApi, scmIntegrationsApi, fetchApi, identityApi }) =>
      new ScaffolderClient({
        discoveryApi,
        scmIntegrationsApi,
        fetchApi,
        identityApi: identityApi,
        useLongPollingLogs: true
      }),
  }),
  createApiFactory({
    api: analyticsApiRef,
    deps: { configApi: configApiRef, identityApi: identityApiRef },
    factory: ({ configApi, identityApi }) => MixpanelAnalytics.fromConfig(configApi, { identityApi }),
  }),
  createApiFactory({
    api: graphQlBrowseApiRef,
    deps: { identityApi: identityApiRef, configApi: configApiRef },
    factory: ({ identityApi, configApi }) =>
      GraphQLEndpoints.from([
        {
          id: 'es',
          title: 'Engineering System',
          fetcher: async (params: any) => {
            const credentials = await identityApi.getCredentials();
            const baseUrl = configApi.getString('backend.baseUrl');
            const response = await fetch(`${baseUrl}/graphql`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${credentials.token}`,
              },
              body: JSON.stringify(params)
            });
            return response.json();
          }
        }
      ]),
  }),
];
