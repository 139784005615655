import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Link, StatusOK } from '@backstage/core-components';
import { CardAccordion, CardAccordionDetails, CardAccordionSummary } from '../../CardAccordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Icons } from '../Icons';
import { Entity } from '@backstage/catalog-model';
import { useTypographyStyles } from '../typographyStyles';
import UploadIcon from '@material-ui/icons/Publish';
import DownloadIcon from '@material-ui/icons/GetApp';

export const MessagingAccordionCard = ({ entity }: { entity: Entity }) => {
  const typoStyles = useTypographyStyles();
  return (
    <CardAccordion>
      <CardAccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Grid container direction="row" spacing={1}>
          <Grid item>
            {Icons.ServiceBusTopic}
          </Grid>
          <Grid item>
            <Typography>
              <Link to={`/catalog/default/component/${entity.metadata.name}`}>{entity.metadata.title ?? entity.metadata.name}</Link>
            </Typography>
          </Grid>
        </Grid>
      </CardAccordionSummary>
      <CardAccordionDetails>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h2" className={typoStyles.label}>
              Description
            </Typography>
            <Typography variant="body2" className={typoStyles.description}>
              {entity.metadata.description ?? 'No description'}
            </Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="h2" className={typoStyles.label}>
                STATUS
              </Typography>
              <Typography variant="body2" className={typoStyles.value}>
                <StatusOK>Unknown</StatusOK>
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2" className={typoStyles.label}>
                TYPE
              </Typography>
              <Typography variant="body2" className={typoStyles.value}>
                Service Bus Topic
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2" className={typoStyles.label}>
                Namespace
              </Typography>
              <Typography variant="body2" className={typoStyles.value}>
                Unknown
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h2" className={typoStyles.label}>
                Connections
              </Typography>
              <Grid container item direction="row" spacing={0}>
                <Grid item><UploadIcon /></Grid>
                <Grid item><Link to=""><Typography className={typoStyles.value}>Unknown</Typography></Link></Grid>
                <Grid item><Typography>&nbsp;</Typography></Grid>
                <Grid item><DownloadIcon /></Grid>
                <Grid item><Link to=""><Typography className={typoStyles.value}>Unknown</Typography></Link></Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item><Link to={`https://dev.azure.com/blackbaud/Products/_git/${entity.spec?.repositoryName}`} variant="body2" className={typoStyles.link}>Source</Link></Grid>
            <Grid item><Link to={`https://dev.azure.com/blackbaud/Products/_git/${entity.spec?.repositoryName}`} variant="body2" className={typoStyles.link}>Pipeline</Link></Grid>
          </Grid>
        </Grid>
      </CardAccordionDetails>
    </CardAccordion>
  );
} 