import React from 'react';
import { Grid, makeStyles, Typography, withStyles } from '@material-ui/core';
import { Gauge, InfoCard, TrendLine } from '@backstage/core-components';
import { useTypographyStyles } from '../typographyStyles';
import { useEntityCosts } from '../EntityCost/EntityCostProvider';

const containerStyles = makeStyles(_ => ({
  zoneCard: {
    minWidth: '200px'
  },
  marginTop: {
    marginTop: '10px'
  }
}));

const SmallGauge = withStyles({
  root: {
    width: '100px'
  },
  overlay: {
    fontSize: '20px'
  }
})(Gauge);

const emptyTrendline = [0, 0];

export const ZoneStatsCard = ({ zone }: { zone: string; }) => {
  const typoStyles = useTypographyStyles();
  const containerStyle = containerStyles();
  const { costs, loading: loadingCosts } = useEntityCosts();

  let costPreviousPeriod = 0;
  let costChange = 0;
  let costMax = 0;
  let costTrend = emptyTrendline;

  if (!loadingCosts && costs) {
    costPreviousPeriod = costs.zones[zone]?.previous ?? 0;
    costChange = costs.zones[zone]?.change ?? 0;
    costTrend = costs.zones[zone]?.trendline ?? emptyTrendline;

    for (const key in costs.zones) {
      if (Object.prototype.hasOwnProperty.call(costs.zones, key)) {
        costMax = Math.max(costMax, ...costs.zones[key]?.trendline);
      }
    }
  }

  return (
    <InfoCard title={zone}>
      <Typography variant="h2" className={typoStyles.label}>
        Live Services
      </Typography>
      <SmallGauge value={0} description="0 / 2" />

      <Grid container spacing={1} className={containerStyle.marginTop}>
        <Grid item xs>
          <Typography variant="h2" className={typoStyles.label}>
            Costs
          </Typography>
          <TrendLine color="blue" height={30} max={costMax} data={costTrend} title="Costs over time (6 months)" />
        </Grid>

        <Grid item xs>
          <Typography variant="body2" className={typoStyles.trendSummaryText}>
            ${costPreviousPeriod.toFixed(2)}
          </Typography>
          <Typography variant="body2" className={typoStyles.trendSummaryPercentChange}>
            {costChange === 0 ? ("No change") : (`${(costChange * 100).toFixed(2)}%`)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs>
          <Typography variant="h2" className={typoStyles.label}>
            Changes
          </Typography>
          <TrendLine color="purple" height={30} data={[0, 0, 0, 0, 0, 0]} title="Change Frequency (6 months)" />
        </Grid>
        <Grid item xs>
          <Typography variant="body2" className={typoStyles.trendSummaryText}>
            0
          </Typography>
          <Typography variant="body2" className={typoStyles.trendSummaryPercentChange}>
            +0%
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs>
          <Typography variant="h2" className={typoStyles.label}>
            Uptime
          </Typography>
          <TrendLine color="orange" height={30} data={[0, 0, 0, 0, 0, 0]} title="Service Health Uptime (6 months)" />
        </Grid>
        <Grid item xs>
          <Typography variant="body2" className={typoStyles.trendSummaryText}>
            0.000%
          </Typography>
          <Typography variant="body2" className={typoStyles.trendSummaryPercentChange}>
            No change
          </Typography>
        </Grid>
      </Grid>
    </InfoCard>
  );
} 