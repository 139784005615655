import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { InfoCard, TrendLine } from '@backstage/core-components';
import { useEntity } from '@backstage/plugin-catalog-react';
import { useTypographyStyles } from '../typographyStyles';
import { useEntityCosts } from '../EntityCost/EntityCostProvider';

const emptyTrendline = [0, 0];

export const SystemAboutCard = () => {
  const typoStyles = useTypographyStyles();
  const { entity } = useEntity();
  const { costs, loading: loadingCosts } = useEntityCosts();

  let costPreviousPeriod = 0;
  let costChange = 0;
  let costMax = 0;
  let costTrend = emptyTrendline;

  if (!loadingCosts && costs) {
    costPreviousPeriod = costs.total?.previous ?? 0;
    costChange = costs.total?.change ?? 0;
    costTrend = costs.total?.trendline ?? emptyTrendline;
    costMax = Math.max(...costs.total?.trendline ?? emptyTrendline);
  }

  return (
    <InfoCard title={entity.spec?.scsLong?.toLocaleString()}>
      <Grid container alignItems='flex-start'>
        <Grid container item md={8}>
          <Grid item xs={12}>
            <Typography variant="h2" className={typoStyles.label}>
              Description
            </Typography>
            <Typography variant="body2" className={typoStyles.description}>
              {entity.spec?.description?.toLocaleString()}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="h2" className={typoStyles.label}>
              Regulated
            </Typography>
            <Typography variant="body2" className={typoStyles.value}>
              {entity.spec?.isRegulated ? "Yes" : "No"}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="h2" className={typoStyles.label}>
              Lifecycle
            </Typography>
            <Typography variant="body2" className={typoStyles.value}>
              {entity.spec?.lifecycle?.toLocaleString() ?? "Unknown"}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="h2" className={typoStyles.label}>
              Service level
            </Typography>
            <Typography variant="body2" className={typoStyles.value}>
              {entity.spec?.sla?.toLocaleString() ?? "Unknown"}
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="h2" className={typoStyles.label}>
              Tags
            </Typography>
            <Typography variant="body2" className={typoStyles.value}>
              {entity.metadata.tags ?? "No Tags"}
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs>
          <Grid item xs>
            <Typography variant="h2" className={typoStyles.label}>
              Costs
            </Typography>
            <TrendLine color="blue" height={30} max={costMax} data={costTrend} title="Costs over time (6 months)" />
            <Typography variant="body2" className={typoStyles.trendSummaryText}>
              ${costPreviousPeriod.toFixed(2)}
            </Typography>
            <Typography variant="body2" className={typoStyles.trendSummaryPercentChange}>
              {costChange === 0 ? ("No change") : (`${(costChange * 100).toFixed(2)}%`)}
            </Typography>
          </Grid>

          <Grid item xs>
            <Typography variant="h2" className={typoStyles.label}>
              Changes
            </Typography>
            <TrendLine color="purple" height={30} data={[0, 0, 0, 0, 0, 0]} title="Change Frequency (6 months)" />
            <Typography variant="body2" className={typoStyles.trendSummaryText}>
              0
            </Typography>
            <Typography variant="body2" className={typoStyles.trendSummaryPercentChange}>
              +0%
            </Typography>
          </Grid>

          <Grid item xs>
            <Typography variant="h2" className={typoStyles.label}>
              Uptime
            </Typography>
            <TrendLine color="orange" height={30} data={[0, 0, 0, 0, 0, 0]} title="Service Health Uptime (6 months)" />
            <Typography variant="body2" className={typoStyles.trendSummaryText}>
              0.000%
            </Typography>
            <Typography variant="body2" className={typoStyles.trendSummaryPercentChange}>
              +0.000%
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </InfoCard>
  );
} 