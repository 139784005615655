function encodeZone(zone: string): string {
  return zone.replace('-', '').toLowerCase()
    .replace('usa', '000')
    .replace('eur', '111')
    .replace('can', '222')
    .replace('aus', '333')
    .replace('p', '0')
    .replace('t', '1')
    .replace('d', '2');
}

export function zoneComparer(a: string, b: string) {
  const first = encodeZone(a);
  const second = encodeZone(b);

  if (first < second) {
    return -1;
  }

  if (first > second) {
    return 1;
  }

  return 0;
}