/*
 * Copyright 2020 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import BuildOutlined from '@material-ui/icons/BuildOutlined';
import Report from '@material-ui/icons/Report';
import Certificate from '@material-ui/icons/Policy';
import { GraphiQLIcon } from '@backstage-community/plugin-graphiql';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  SidebarSpace,
  Link,
  GroupIcon,
  SidebarSubmenu,
  SidebarSubmenuItem,
} from '@backstage/core-components';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { MyGroupsSidebarItem } from '@backstage/plugin-org';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
  logoSubtitle: {
    width: 175,
    textTransform: 'uppercase',
    fontSize: 11,
    color: '#eee',
    fontWeight: 'bold',
    letterSpacing: '0.85px'
  }
});

const BlackbaudLogo = () => {
  return (
    <img style={{maxWidth: 175}} src="/blackbaud-logo-green.png" />
  )
};

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        <BlackbaudLogo />
        <div className={classes.logoSubtitle}>
          Engineering System Hub
        </div>
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>

      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Show teams the user is on, if any -- hides completely if no team is found */}
        <MyGroupsSidebarItem
          singularTitle="My Team"
          pluralTitle="My Teams"
          icon={GroupIcon}
        />
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="catalog" text="Catalog" />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarScrollWrapper>
          <SidebarItem icon={BuildOutlined} text="Tools">
            <SidebarSubmenu title="Tools">
              <SidebarSubmenuItem icon={CreateComponentIcon} to="create" title="Create..." subtitle="Run scaffolder templates" />
              <SidebarSubmenuItem icon={Certificate} to="/internal/rotation-tracking" title="Rotation Tracking" subtitle="Track resources that need manual rotation" />
              <SidebarSubmenuItem icon={GraphiQLIcon} to="/graphiql" title="GraphiQL" />
              <SidebarDivider />
              <SidebarSubmenuItem icon={Report} to="https://host.nxt.blackbaud.com/upsilon-kpis/availability?live=true&prod=true&type=Aks&svcid=engsys" title="Uptime Report" />
            </SidebarSubmenu>
          </SidebarItem>
        </SidebarScrollWrapper>
      </SidebarGroup>
      <SidebarSpace />
      <SidebarDivider />
      {/* SubmenuItem's support external URLs correctly, SidebarItem doesn't -- fix later */}
      <SidebarSubmenuItem to="https://app.blackbaud.com/engsys-hub" title="Hub Classic" subtitle="https://app.blackbaud.com/engsys-hub/" />
      <SidebarSubmenuItem to="https://dev.azure.com/blackbaud/Products/_wiki/wikis/ES%20Platform%20Docs/" title="Documentation" subtitle="ES ADO Wiki" />

      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
