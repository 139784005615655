import React from 'react';
import {
  Table,
  StatusOK,
  StatusWarning,
  TableColumn,
  SubvalueCell,
} from '@backstage/core-components';
import { Box, Typography } from '@material-ui/core';
import { useEngineeringPlatform } from '../../useEngineeringPlatform';
import { calculateExpirationDate, formatDate } from '../CommonFunctions';

const generateCertificateData = () => {
  const { entities } = useEngineeringPlatform({
    kind: 'resource',
    type: 'tls certificate',
  });

  if (!entities) return [];

  const data = entities.map(item => ({    
    type: item.spec?.certificateType,
    commonname: item.spec?.CommonName,
    managed: item.spec?.autoManged,
    textmanaged: item.spec?.autoManged ? ' Automatic': ' by BLKB',
    host: item.metadata?.name,
    expiresin: calculateExpirationDate(`${item.spec?.['Expiration Date']}`),
    expiration: formatDate(`${item.spec?.['Expiration Date']}`),
    thumbprint: `${item.spec?.['Thumbprint/Fingerprint']}`.replace(/[:]/g, ''),
    organization: item.spec?.Organization,
    issuer: item.spec?.Issuer    
  }));

  return data;
};

const columns: TableColumn[] = [
  {
    title: 'Type',
    customFilterAndSearch: (
      query,
      row: any, // Only needed if you want subvalue searchable
    ) =>
      `${row.type} ${row.commonname}`
        .toLocaleUpperCase('en-US')
        .includes(query.toLocaleUpperCase('en-US')),
    field: 'type',
    highlight: true,
    render: (row: any): React.ReactNode => (
      <SubvalueCell value={row.type} subvalue={row.commonname} />
    ),
    width: '35%',
  },
  {
    title: 'Managed',
    render: (row: Partial<TableCertData>) => (
      <Box display="flex" alignItems="center">        
        {row.managed ? (<StatusOK />) : (<StatusWarning />) }
        <Typography variant="body2">{row.managed} {row.textmanaged}</Typography>
      </Box>
    ),
    width: '5%',
  },
  {
    title: 'Host',
    field: 'host',
    width: '20%',
  },
  {
    title: 'Expires in',
    field: 'expiresin',
    type: 'numeric',
    width: '5%',
  },
  {
    title: 'Expiration',
    field: 'expiration',
    width: '5%',
  },
  {
    title: 'Info',
    customFilterAndSearch: (
      query,
      row: any, // Only needed if you want subvalue searchable
    ) =>
      `Thumbprint= ${row.thumbprint}`
        .toLocaleUpperCase('en-US')
        .includes(query.toLocaleUpperCase('en-US')),
    field: 'type',
    highlight: true,
    render: (row: any): React.ReactNode => (
      <>
        <SubvalueCell value="Thumbprint" subvalue={row.thumbprint} />        
        <div style={{ height: '11px' }} />
        <SubvalueCell value="Common Name" subvalue={row.commonname} />
        <div style={{ height: '11px' }} />
        <SubvalueCell value="Organization" subvalue={row.organization} />
        <div style={{ height: '11px' }} />
        <SubvalueCell value="Issuer" subvalue={row.issuer} />
      </>
    ),
  },
];

export const Certificates = () => {
  return (
      <Table
        options={{ paging: false, padding: 'dense' }}
        data={generateCertificateData()}
        columns={columns}
        title="Certificates"
        subtitle=""
      />
  );
};

interface TableCertData {
  type: string;
  managed: boolean;
  textmanaged: string;
  host: string;
  expiresin: number;
  expiration: string;
  info: string;
}
