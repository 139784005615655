import Grid from '@material-ui/core/Grid';
import React from 'react';

export const HomePage = (
  <Grid container spacing={3}>
    <Grid item xs={12} md={4}>
      <div>Future Home page for Blackbaud Engineer</div>
    </Grid>
  </Grid>
);
