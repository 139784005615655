import { useApi } from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  humanizeEntityRef,
} from '@backstage/plugin-catalog-react';
import { FieldExtensionComponentProps, createScaffolderFieldExtension } from '@backstage/plugin-scaffolder-react';
import { scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { TextField } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useCallback, useEffect } from 'react';
import { useAsync } from 'react-use';

export const BlackbaudEntityPicker = ({
  onChange,
  schema: { title = 'Entity', description = 'An entity from the catalog' },
  required,
  uiSchema,
  rawErrors,
  formData,
  idSchema,
}: FieldExtensionComponentProps<string>) => {
  const allowedKinds = uiSchema['ui:options']?.allowedKinds as string[];
  const defaultKind = uiSchema['ui:options']?.defaultKind as string | undefined;

  const catalogApi = useApi(catalogApiRef);
  const { value: entities, loading } = useAsync(() =>
    catalogApi.getEntities(
      allowedKinds ? { filter: { kind: allowedKinds } } : undefined,
    ),
  );

  const entityTitles = entities?.items.reduce((map, current) => {
    const entityRef = humanizeEntityRef(current, { defaultKind });
    map.set(entityRef, current.metadata.title ?? entityRef);
    return map;
  }, new Map<string, string>());

  const entityRefs = Array.from(entityTitles?.keys() ?? []);

  const onSelect = useCallback(
    (_: any, value: string | null) => {
      onChange(value ?? undefined);
    },
    [onChange],
  );

  useEffect(() => {
    if (entityRefs?.length === 1) {
      onChange(entityRefs[0]);
    }
  }, [entityRefs, onChange]);

  return (
    <FormControl
      margin="normal"
      required={required}
      error={rawErrors?.length > 0 && !formData}
    >
      <Autocomplete
        disabled={entityRefs?.length === 1}
        id={idSchema?.$id}
        loading={loading}
        value={!loading && formData || ''}
        onChange={onSelect}
        options={entityRefs || []}
        getOptionLabel={(option) => entityTitles?.get(option) ?? ''}
        autoSelect
        renderInput={params => (
          <TextField
            {...params}
            label={title}
            margin="dense"
            helperText={description}
            FormHelperTextProps={{ margin: 'dense', style: { marginLeft: 0 } }}
            variant="outlined"
            required={required}
            InputProps={params.InputProps}
          />
        )}
      />
    </FormControl>
  );
};

export const BlackbaudEntityPickerFieldExtension = scaffolderPlugin.provide(
  createScaffolderFieldExtension({
    component: BlackbaudEntityPicker,
    name: 'BlackbaudEntityPicker',
  }),
);