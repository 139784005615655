import {
  AnalyticsApi,
  AnalyticsEvent,
  ConfigApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import mixpanel from 'mixpanel-browser';

export class MixpanelAnalytics implements AnalyticsApi {
  private constructor(projectToken: string, identityApi?: IdentityApi) {
    mixpanel.init(projectToken, { debug: true, ignore_dnt: true });
    if (identityApi) {
      identityApi.getBackstageIdentity().then(identity => {
        mixpanel.identify(identity.userEntityRef);
      });
    }
  }

  static fromConfig(config: ConfigApi, options: { identityApi: IdentityApi }) {
    const projectToken = config.getString('app.analytics.mixpanel.projectToken');
    return new MixpanelAnalytics(projectToken, options?.identityApi);
  }

  captureEvent(event: AnalyticsEvent) {
    const { action, ...rest } = event;
    mixpanel.track(action, rest);
  }
}