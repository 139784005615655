import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import {
  EntityLayout,
} from '@backstage/plugin-catalog';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import { EntityWarningContent } from '../EntityWarning';
import { SystemAboutCard } from '../SystemAboutCard/SystemAboutCard';
import { SystemServicesCard } from './SystemServicesCard';
import { SystemMessagingCard } from './SystemMessagingCard';
import { SystemZonesCard } from './SystemZonesCard';
import { EntityCostsProvider } from '../EntityCost/EntityCostProvider';
import { SystemSpasCard } from './SystemSpasCard';

const useContainerStyles = makeStyles(_ => ({
  entityGridItem: {
    width: '100%'
  },
}));

export const SystemPage = () => {
  const containerStyles = useContainerStyles();
  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <EntityCostsProvider>
          <Grid container spacing={3}>
            <EntityWarningContent />
            <Grid item sm={7} md={8} lg={9} xl={10}>
              <Grid container item alignItems='stretch'>
                <Grid item xs={12}>
                  <SystemAboutCard />
                </Grid>
                <Grid container item xs={12} spacing={0}>
                  <SystemZonesCard />
                </Grid>
              </Grid>
            </Grid>
            <Grid item container sm={5} md={4} lg={3} xl={2} direction='column' spacing={0}>
              <Grid container item spacing={2}>
                <Grid item className={containerStyles.entityGridItem}>
                  <SystemServicesCard variant='gridItem' />
                </Grid>
                <Grid item className={containerStyles.entityGridItem}>
                  <SystemMessagingCard variant='gridItem' />
                </Grid>
                <Grid item className={containerStyles.entityGridItem}>
                  <SystemSpasCard variant='gridItem' />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </EntityCostsProvider>
      </EntityLayout.Route>
      <EntityLayout.Route path="/diagram" title="Diagram">
        <EntityCatalogGraphCard
          variant="gridItem"
          direction={Direction.TOP_BOTTOM}
          title="SCS Diagram"
          height={700}
          relations={[
            RELATION_PART_OF,
            RELATION_HAS_PART,
            RELATION_API_CONSUMED_BY,
            RELATION_API_PROVIDED_BY,
            RELATION_CONSUMES_API,
            RELATION_PROVIDES_API,
            RELATION_DEPENDENCY_OF,
            RELATION_DEPENDS_ON,
          ]}
          kinds={["System", "Component"]}
          zoom="enable-on-click"
          unidirectional
        />
      </EntityLayout.Route>
    </EntityLayout>
  );
}